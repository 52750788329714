<template>
  <v-app>
    <v-main>
      <banner />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <Footer />
    <div v-show="isLoading" class="loading_circle">
      <v-progress-circular
        indeterminate
        :size="50"
        color="primary"
      ></v-progress-circular>
    </div>
  </v-app>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Banner,
    Footer,
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
  },
};
</script>

<style lang="scss" src="@/assets/style/main.scss" />
<style>
/* scroll bar
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
 */

.v-application--wrap {
  padding-bottom: 60px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-delay: 0.1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.loading_circle {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
