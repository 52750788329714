import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'lineLiff',
    component: () => import('../views/linePortal.vue')
  },
  {
    path: '/invoiceLog',
    name: 'InvoiceLog',
    component: () => import('../views/InvoiceLog.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('../views/Activity.vue')
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/Member.vue')
  },
  {
    path: '/exchange',
    name: 'Exchange',
    component: () => import('../views/Exchange.vue')
  },
  {
    path: '/electricInvoice',
    name: 'ElectricInvoice',
    component: () => import('../views/ElectricInvoice.vue')
  },
  {
    path: '/electricInvoiceHand',
    name: 'ElectricInvoiceHand',
    component: () => import('../views/ElectricInvoiceHand.vue')
  },
  {
    path: '/electricInvoicePhoto',
    name: 'ElectricInvoicePhoto',
    component: () => import('../views/ElectricInvoicePhoto.vue')
  },
  {
    path: '/carrier',
    name: 'Carrier',
    component: () => import('../views/Carrier.vue')
  },
  {
    path: '/carrierUpload',
    name: 'CarrierUpload',
    component: () => import('../views/CarrierUpload.vue')
  },
  {
    path: '/tradInvoice',
    name: 'TradInvoice',
    component: () => import('../views/TradInvoice.vue')
  },
  {
    path: '/tradInvoiceUpload',
    name: 'TradInvoiceUpload',
    component: () => import('../views/TradInvoiceUpload.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('../views/Result.vue')
  },
  {
    path: '/ScanEvent',
    name: 'ScanEvent',
    component: () => import('../views/ScanEvent.vue'),
    params: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
