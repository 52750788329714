<template>
  <div class="home page-container">
    <PageTitle title="活動首頁" />
    <BaseBtn
      v-for="(item, i) in homeList"
      :key="i"
      :text="item.text"
      type="class"
      :isOutlined="true"
      color="#888"
      @click="routeTo(item.route)"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";

export default {
  name: "Home",
  components: {
    PageTitle,
    BaseBtn,
  },
  data() {
    return {
      homeList: [
        {
          text: "活動辦法",
          route: "/Activity",
        },
        // {
        //   text: "登錄發票",
        //   route: "/InvoiceLog",
        // },
        {
          text: "點數查詢",
          route: "/Member",
        },
        {
          text: "兌換專區",
          route: "/Exchange",
        },
      ],
    };
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
  },
};
</script>