<template>
  <h2 class="page-title">{{ title }}</h2>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="scss">
.page-title {
  position: relative;
  font-size: 26px;
  margin-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 60px;
    height: 2px;
    background: #c3131b;
  }
}
</style>