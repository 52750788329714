import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#C3131B',
        secondary: '#333333',
        error: '#C3131B',
        txt: {
          light: '#fff',
          dark: '#333'
        }
      },
    },
  },

});
