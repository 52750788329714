<template>
  <v-footer fixed class="footer">
<!--    <p>禁止酒駕</p>-->
<!--    <img src="@/assets/img/icon/wine-stop.svg" alt="" class="footer_img"/>-->
<!--    <p>未滿十八歲禁止飲酒</p>-->
    <img src="@/assets/img/alert.svg" alt="">
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
.footer {
  background-color: #555 !important;
  height: 60px;
  //padding: 20px 18px;

  img{
    width: 100%;
    height: 100%;
    transform: scale(1.35);
  }

  //.footer_img {
  //  margin: 0 3px;
  //  width: 35px;
  //  height: 35px;
  //  transform: translate(0px, 9px);
  //}
  //
  //p {
  //  letter-spacing: 8px;
  //}
}
</style>