import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    invoice : {
      hasData: false,
      data: {},
    },
    isLoading: false,
  },
  getters: {
    getLoading(state) {
      return state.isLoading;
    },
  },
  mutations: {
    updateInvoice (state, data) {
      state.invoice = data
    },
    updateLoading(state, data) {
      state.isLoading = data
    },

  },
  actions: {
    updateInvoice (context) {
      context.commit('updateInvoice')
    },
  },
  modules: {
  }
})
