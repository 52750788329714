<template>
  <div class="banner">
    <img :src="bannerImg" alt="" />
  </div>
</template>

<script>
export default {
  name: 'Banner',
  computed: {
    bannerImg() {
      let imgName = this.$route.path === '/home' ? 'banner-home' : 'banner'
      if (
        new Date().getTime() >=
        new Date(process.env.VUE_APP_NEW_EVENT_DATE).getTime()
      ) {
        imgName += '-20230901'
      }
      return require(`@/assets/img/${imgName}.jpg`)
    }
  }
}
</script>

<style lang="scss">
.banner {
  border-bottom: 1px solid #555;
  img {
    display: block;
    width: 100%;
  }
}
</style>
